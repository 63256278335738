import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import api from '../../services/api';
import { jwtDecode } from 'jwt-decode';

const WelcomePage = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const error = queryParams.get('error');

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        decodedToken.isAdmin = decodedToken.isAdmin ? 1 : 0;
    
        localStorage.setItem('token', token);
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
        login({ token, user: decodedToken });

        setTimeout(() => {
          navigate('/dashboard');
        }, 1000);
      } catch (e) {
        navigate('/login?error=token_invalid'); // Redireciona com erro
      }
    } else if (error) {
      navigate(`/login?error=${error}`); // Redireciona o erro para a tela de login
    }
  }, [location.search, login, navigate]);

  return null; // Não renderiza nada, pois sempre redireciona
};

export default WelcomePage;
